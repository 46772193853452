import { Show, Suspense, useContext, Match, Switch } from 'solid-js';
import { Button, Link } from '@troon/ui';
import { Meta, Title } from '@solidjs/meta';
import { IconStar, IconStarFilled } from '@troon/icons';
import { useAction } from '@solidjs/router';
import { FacilityHeader } from '../../../components/facility/header';
import { Content } from '../../../components/content';
import { FacilityCtx } from '../../../providers/facility';
import { Grid, GridMain, GridSidebar } from '../../../components/layouts/grid';
import { Carousel } from '../../../components/carousel';
import { gql, mutationAction, useMutation } from '../../../graphql';
import { getFacilitySchema } from '../../../modules/schema/facility';
import { GenericLayout } from '../../../layouts/generic';
import { FacilityLocation } from '../../../components/facility/location';
import type { Course, Facility, FacilityLocationFragment } from '../../../graphql';

export default function Course() {
	const facility = useContext(FacilityCtx);
	const handleFavoriteAction = useAction(useMutation(handleFavorite));

	return (
		<GenericLayout hero={facility()?.facility?.metadata?.hero?.url}>
			<Show when={facility()?.facility}>
				{(facility) => (
					<>
						<Title>{`${facility()?.name} | Troon Rewards | Book tee times`}</Title>
						<Meta
							name="description"
							content={`${facility()?.metadata?.description?.slice(0, 154) ?? 'Book tee times at your favorite courses with Troon'}…`}
						/>
						<script type="application/ld+json" innerText={getFacilitySchema(facility() as Facility)} />
					</>
				)}
			</Show>

			<Suspense fallback={<div>loading…</div>}>
				<Show when={facility()}>
					<Content>
						<FacilityHeader bordered facility={facility()?.facility ?? {}} linked={false}>
							<div class="flex flex-row justify-end gap-4">
								<div class="grow lg:grow-0">
									<Button as={Link} href={`/course/${facility()!.facility.slug!}/reserve-tee-time`} class="shrink">
										Book a tee time
									</Button>
								</div>
								<div class="shrink">
									<Button
										appearance="secondary"
										onClick={() => {
											const data = new FormData();
											data.set('facilityId', facility()?.facility.id ?? '');
											data.set('isFavorite', facility()?.facility.isFavorite ? 'false' : 'true');
											handleFavoriteAction(data);
										}}
									>
										<Switch>
											<Match when={facility()?.facility.isFavorite}>
												<IconStarFilled class="text-xl" />
											</Match>
											<Match when>
												<IconStar class="text-xl" />
											</Match>
										</Switch>
										<span class="sr-only">Favorite</span>
									</Button>
								</div>
							</div>
						</FacilityHeader>

						<Grid>
							<GridMain class="flex flex-col gap-8">
								<section class="flex flex-col gap-4">
									<h2 class="text-xl font-semibold md:text-2xl">Overview</h2>
									<p innerHTML={facility()?.facility?.metadata?.description ?? ''} />
								</section>

								<Show when={facility()?.facility.metadata?.gallery.length}>
									<section class="flex flex-col gap-4">
										<h2 class="text-xl font-semibold md:text-2xl">Course photos</h2>
										<Carousel
											images={facility()!.facility.metadata!.gallery.map(({ url }) => ({
												url,
												alt: `Landscape putting greens and fairway photo of the ${facility()!.facility.name} golf course`,
											}))}
										/>
									</section>
								</Show>
							</GridMain>

							<GridSidebar>
								<div class="flex flex-col gap-8">
									<Show when={facility()?.facility}>
										{(facility) => <FacilityLocation {...(facility() as FacilityLocationFragment)} />}
									</Show>
								</div>
							</GridSidebar>
						</Grid>
					</Content>
				</Show>
			</Suspense>
		</GenericLayout>
	);
}

const toggleFavorite = gql(`
mutation toggleFavoriteFacility($facilityId: String!, $isFavorite: Boolean!) {
	toggleFavoriteFacility(facilityId: $facilityId, isFavorite: $isFavorite) {
		name
		isFavorite
	}
}
`);

const handleFavorite = mutationAction(toggleFavorite, {
	transform: (data) => ({
		facilityId: data.get('facilityId') as string,
		isFavorite: data.get('isFavorite') === 'true',
	}),
	toast: (data) =>
		data?.toggleFavoriteFacility.isFavorite
			? `Added ${data?.toggleFavoriteFacility.name} as a favorite`
			: `Removed ${data?.toggleFavoriteFacility.name} as a favorite.`,
	revalidates: ['facility', 'home', 'facilities', 'searchFacilitiesList'],
});
